<template>
  <div class="container">
    <h3 class="text-center my-3">Силабусы преподавателей</h3>
    <div class="search-field my-3 d-flex flex-column">
      <span class="p-input-icon-left col-12 col-md-5">
        <i class="pi pi-search" />
        <InputText v-model="searchingName" placeholder="Логин/Баркод/Имя/Фамилия"
                   aria-describedby="username-help" class="col-12"/>
      </span>
    </div>
    <div v-if="data.disciplines.length > 0">
      <DataTable :value="filteredData" :paginator="true" :rows="20"
                 paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                 :rowsPerPageOptions="[10, 20, 50, 100, 200]"
                 currentPageReportTemplate="Показано {last} из {totalRecords}"
                 stripedRows responsiveLayout="scroll">
        <Column field="fio" header="ФИО"></Column>
        <Column header="Силабус">
          <template #body="{data}">
            <Button label="Силабусы" aria-label="Submit" @click="openSyllabusModal(data.user_id)"/>
          </template>
        </Column>
      </DataTable>
    </div>
  </div>
  <Dialog v-model:visible="syllabusModalVisible" modal :header="`Силабусы преподавателя ${selectedTeacherData.fio}`" :style="{ width: '50wv' }">
    <DataTable :value="selectedTeacherData.disciplines" :paginator="true" :rows="5"
               paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
               stripedRows responsiveLayout="scroll">
      <Column header="Дисциплина" field="discipline_name"></Column>
      <Column header="Открыть силабус">
        <template #body="{data}">
          <a :href="`https://back.uib.kz/syllabus_data/syllabus-print/get-syllabus?
                     user_id=${selectedTeacherData.user_id}&
                     education_discipline_id=${data.discipline_id}&
                     syllabus_id=${data.syllabus_id}`"
             target="_blank">
            <Button icon="pi pi-external-link" aria-label="Submit"/>
          </a>
        </template>
      </Column>
    </DataTable>
    <template #footer>
      <Button icon="pi pi-times" label="Закрыть" aria-label="Close" @click="closeSyllabusModal"/>
    </template>
  </Dialog>
</template>

<script>
import {mapActions, mapState} from "vuex";

export default {
  name: "ShowSyllabus",
  data(){
    return {
      searchingName: '',
      syllabusModalVisible: false,
      selectedTeacherData: {}
    }
  },
  computed:{
    ...mapState('departmentShowSyllabus', ['data']),
    filteredData(){
      return this.data.disciplines.filter(i => i.fio.toLowerCase().includes(this.searchingName))
    }
  },
  methods:{
    ...mapActions('departmentShowSyllabus', ['GET_DEPARTMENT_DISCIPLINES']),
    openSyllabusModal(user_id){
      this.syllabusModalVisible = true
      this.selectedTeacherData = this.data.disciplines.find(i => i.user_id = user_id)
    },
    closeSyllabusModal(){
      this.syllabusModalVisible = false
      this.selectedTeacherData = {}
    }
  },
  mounted() {
    this.GET_DEPARTMENT_DISCIPLINES();
  }
}
</script>


<style scoped>

</style>